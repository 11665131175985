import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { XGrid } from '@material-ui/x-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { getSettings } from '../reducers/ConfigReducer';
import Content from './Content';
import { getInitials } from 'utils/functions';
import { getService } from '../reducers/service';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@mui/material/Grid';
import Menu from '@material-ui/core/Menu';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Tooltip from 'components/Tooltip';

import options from 'components/EventTypeIcons';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  edit: {
    padding: theme.spacing(2, 0),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    flexGrow: 1,
  },
  buttons: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  textField: {
    margin: '0 4px 8px',
  },
  list: {
    width: '100%',
    height: 500,
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  iconFilter: {
    textAlign: 'center'
  }
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const iconRender = (params) => {
  const Icon = options[params.value];
  return Icon ? <Icon /> : <span></span>;
};

const initialsColumns = [
  { field: 'Code', headerName: 'Event Type', width: 250 },
  { field: 'Initial', headerName: 'Initials', width: 200 },
  { field: 'Description', headerName: 'Description', width: 300 },
  { field: 'Custom', headerName: 'Custom', width: 150, format: "bool", renderCell: bool },
  { field: 'Icon', headerName: 'Icon', width: 150, renderCell: iconRender },
];

const ITEM_HEIGHT = 48;

function EventTypeInitials(props) {
  const classes = useStyles();
  const service = getService('settings-event-type-initials');
  const { dictionary } = props;
  const { CallTypes } = dictionary;
  const [view, setView] = useState('list');
  const [Code, setCode] = useState('');
  const [selection, setSelection] = useState([]);
  const [edit, setEdit] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [eventTypes, setEventTypes] = useState([]);
  const [initial, setInitial] = useState('');
  const [selectedIconIndex, setSelectedIconIndex] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [Description, setDescription] = useState('');

  //icon options menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleIconOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
    setFilterText('');
  };

  const handleIconOptionsClose = () => { setAnchorEl(null); };

  const handleMenuItemClick = (ev, idx) => {
    setSelectedIconIndex(idx);
    setAnchorEl(null);
  }

  useEffect(() => {
    renderList();
  }, [dictionary]);

  const renderList = async () => {
    const evTypes = CallTypes
      .map(row => {
        return {
          ...row,
          Initial: getInitials(row.Code, row.Description),
          Custom: false,
          Icon: 0,
          id: row.Code
        }
      });
    const eventTypes = await updateList(evTypes);
    setEventTypes(eventTypes);
  }

  const updateList = async (evTypes) => {
    const fetchedInitials = await service.find();
    fetchedInitials.forEach(f => {
      evTypes.forEach(et => {
        if (et.Code === f.CDRKey && (et.Initial !== f.ValueString || et.Icon !== f.ValueNumeric)) {
          et.Initial = f.ValueString;
          et.Icon = f.ValueNumeric;
          et.Custom = true;
        }
      });
    });
    return evTypes;
  }

  const save = async () => {
    props.showSpinner();
    try {
      let result;
      if (view === 'add') result = await service.create({ CDRKey: Code, ValueString: initial, ValueNumeric: selectedIconIndex, Description });
      else result = await service.patch(Code, { CDRKey: Code, ValueString: initial, ValueNumeric: selectedIconIndex, Description });
      if (typeof result === 'string') {
        setSaveError(result);
        props.hideSpinner();
        return;
      }
      setSaveError(null);
      props.getSettings();
      setView('list');
      setSelectedIconIndex(0);
      setFilterText('');
    } catch (err) {
      props.handleError(err, 'Error saving initials');
    }
    props.hideSpinner();
    setEdit(false);
  }

  const renderEditInitial = () => {
    const CodeErrorHelpertext = () => {
      if (Code.length > 10) return '* Code should be 10 characters max!';
      if (saveError) return `* ${saveError}`;
    }

    const handleCodeChange = (ev) => {
      setCode(ev.target.value);
      setSaveError(null);
    }

    const MenuIcon = options[selectedIconIndex];

    return (
      <div className={classes.edit}>
        <div className={classes.form}>
          <TextField
            className={classes.textField}
            error={Code.length > 10 || typeof saveError === 'string'}
            helperText={CodeErrorHelpertext()}
            label="Event Type"
            value={Code}
            onChange={handleCodeChange}
            variant='outlined'
            size="small"
            disabled={edit}
          />
          <TextField
            error={initial.length > 3}
            helperText={initial.length <= 3 ? '' : "* Max 3 Chars!"}
            className={classes.textField}
            label="Initials"
            value={initial}
            onChange={ev => setInitial(ev.target.value)}
            variant='outlined'
            size="small"
          />
          <TextField
            className={classes.textField}
            label="Description"
            value={Description}
            onChange={ev => setDescription(ev.target.value)}
            variant='outlined'
            size="small"
          />
          <div>
            <Fab
              className="ml-2 mr-2"
              size="small"
              color="primary"
              onClick={handleIconOptionsClick}
            >
              <MenuIcon />
            </Fab>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleIconOptionsClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 10,
                  width: '48ch',
                },
              }}
            >
              <Grid container >
                {/* <Grid item xs={12} className={`${classes.iconFilter} m-3`}>
                  <TextField
                    className={classes.textField}
                    label="search"
                    id="outlined-search"
                    value={filterText}
                    onChange={ev => setFilterText(ev.target.value)}
                    variant='outlined'
                    size="small"
                    type="search"
                    fullWidth
                  />
                </Grid> */}
                {Object.entries(options)
                  .filter(option => {
                    const iconName = option[1].name;
                    return iconName.toLowerCase().includes(filterText.toLowerCase());
                  })
                  .map(([key, Icon]) => (
                    <span className="m-3" key={key} onClick={(event) => handleMenuItemClick(event, key)}>
                      <Fab size="small" color="inherit"><Icon /></Fab>
                    </span>
                  ))
                }
              </Grid>
            </Menu>
          </div>
          <div className={classes.addressWrap}>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                disabled={!Code || !initial || Code.length > 10 || initial.length > 3}
                onClick={save}
              >
                <SaveIcon />
                Save
              </Button>
              <Button
                color="primary"
                onClick={handleClose}
              >
                <CloseIcon />
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const delInitial = async () => {
    if (!window.confirm('Are you sure you want to remove this initials?')) return;
    try {
      service.remove({ id: selection[0], type: 'icon' });
      renderList();
      props.getSettings();
    } catch (err) {
      props.handleError(err, 'Error deleting initials');
    }
  }

  const editInitial = () => {
    const EventType = eventTypes.find(e => e.Code === selection[0]);
    if (!EventType) return;
    setCode(EventType.Code);
    setInitial(EventType.Initial);
    setSelectedIconIndex(EventType.Icon);
    setDescription(EventType.Description);
    setView('edit');
    setEdit(true);
  }

  const addInitial = () => {
    setCode('');
    setInitial('');
    setSelectedIconIndex(0);
    setDescription('');
    setView('add');
    setEdit(false);
  }

  const handleClose = () => {
    setView('list');
    setSelectedIconIndex(0);
    setFilterText('');
  }

  const deleteEventType = () => {
    if (!window.confirm('Do you want to delete this event type?')) return;
    try {
      service.remove({ id: selection[0], type: 'eventType' });
      renderList();
      props.getSettings();
    } catch (err) {
      props.handleError(err, 'Error deleting event type');
    }
  }

  const renderActions = () => {
    const disabled = selection.length === 0;
    const selected = eventTypes.find(e => e.Code === selection[0]);
    const dltDisabled = selected?.Custom === false;
    return (
      <div className={classes.actions}>
        <Fab size="small" color="secondary" onClick={addInitial}>
          <AddIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={editInitial} disabled={disabled}>
          <EditIcon />
        </Fab>
        <Tooltip title="Delete Icon" onClick={delInitial} disabled={disabled || dltDisabled}>
          <Fab size="small" color="secondary" >
            <DeleteIcon />
          </Fab>
        </Tooltip>
        <Tooltip title="Delete Event Type" onClick={deleteEventType} disabled={disabled}>
        <Fab size="small" color="secondary">
          <RemoveIcon />
        </Fab>
        </Tooltip>
        {view !== 'list' && <Fab size="small" color="secondary" onClick={handleClose}>
          <CloseIcon />
        </Fab>}
      </div>
    );
  }

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  }

  const renderInitials = () => {
    return (
      <div className={classes.list}>
        <XGrid
          columns={initialsColumns}
          rows={eventTypes}
          rowHeight={38}
          onSelectionModelChange={handleSelectChange}
          disableSelectionOnClick={view === 'edit'}
          disableMultipleSelection
          hideFooter
        />
      </div>
    );
  }

  return (
    <Content>
      <div className={classes.wrap}>
        <h4> Event Type Initials and Icons </h4>
        {(view === 'add' || view ==='edit') && renderEditInitial()}
        {view === 'list' && renderActions()}
        {renderInitials()}
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    dictionary: state.config.dictionary
  }
}

export default connect(mapStateToProps, {
  handleError, showSpinner, hideSpinner, getSettings
})(EventTypeInitials);
