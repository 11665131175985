import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ThemeReducer from './ThemeReducer';
import WebsocketReducer from './WebsocketReducer';
import NotifierReducer from './NotifierReducer';
import UiReducer from './UiReducer';
import UserReducer from './UserReducer';
import AppInfoReducer from './AppInfoReducer';
// import EventsReducer from './EventsReducer';
// import UnitsReducer from './UnitsReducer';
import ConfigReducer from './ConfigReducer';
// import EventUnitsReducer from './EventUnitsReducer';
// import UnitStatusReducer from './UnitStatusReducer';
import MapReducer from './MapReducer';
import ZonesReducer from './ZonesReducer';
import PagingReducer from './PagingReducer';
import DialogsReducer from './DialogsReducer';
import DataChangeReducer from './DataChangeReducer';
import { reducer as notifications } from 'react-notification-system-redux';
import MenuReducer from './MenuReducer';
import DictionaryReducer from './DictionaryReducer';
import DataUpdateReducer from './DataUpdateReducer';
import TimeReducer from './TimeReducer';
import KeyboardReducer from './KeyboardReducer';
import CitationReducer from './CitationReducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    theme: ThemeReducer,
    websocket: WebsocketReducer,
    notifier: NotifierReducer,
    notifications: notifications,
    ui: UiReducer,
    user: UserReducer,
    // events: EventsReducer,
    // units: UnitsReducer,
    appInfo: AppInfoReducer,
    config: ConfigReducer,
    // eventUnits: EventUnitsReducer,
    // unitStatus: UnitStatusReducer,
    map: MapReducer,
    zones: ZonesReducer,
    paging: PagingReducer,
    dialogs: DialogsReducer,
    menu: MenuReducer,
    dataChange: DataChangeReducer,
    dictionary: DictionaryReducer,
    dataUpdate: DataUpdateReducer,
    time: TimeReducer,
    keyboard: KeyboardReducer,
    citations: CitationReducer,
  });

export default createRootReducer;
