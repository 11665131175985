import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';
import { Alert, Snackbar } from '@mui/material';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, Grid, TextField, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { getService } from 'reducers/service';
import {
  formatCommonCodes,
  formatPartyRelationShip,
  formatCities,
  formatCodeDispositions,
  formatCodeZonesTypes,
  formatCodeUnitActions,
  formatCodeVehicleMakeModels,
  formatCodeZones,
  formatTagTypes,
} from 'Settings/LookupCodes/CodeObjectFormatter';
const styles = makeStyles(theme => ({
  fieldsetWrapperCitationParty: {
    border: '1px solid #1e8dfa',
    borderRadius: '10px',
    padding: '15px 15px 15px',
    marginTop: '25px',
  },
  fieldsetTitleCitationParty: {
    position: 'absolute',
    marginTop: '-28px',
    color: 'white',
    background: '#1e8dfa',
    borderRadius: '10px',
    padding: '2px 8px',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
}));
const obj = {
  Code: '',
  Description: '',
  Category: '',
  IsDefault: false,
  IsActive: false,
  Code2: '',
  Description2: '',
  Code3: '',
  Description3: '',
  Code4: '',
  Description4: '',
  Entities: null,
  EmployeeRelated: false,
  Order: null,
  CityDescription: '',
  Zone1Type: '',
  Zone2Type: '',
  Zone3Type: '',
  Zone4Type: '',
  Zone5Type: '',
  WaitTime: null,
  ModelCode: '',
  MakeDescription: '',
  ModelDescription: '',
};

export default function AgencyAddEditDialog(props) {
  const classes = styles();
  const { title, open, closeDialog, mode, data, currentService } = props;
  //console.log('mode ', mode);
  const [values, setValues] = useState(obj);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [makeOptions, setMakeOptions] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  /* handler functions -> start */
  const handleCloseAlert = () => setOpenAlert(false);
  const handleChange = (key, value) => {
    setValues(prevState => ({ ...prevState, [key]: value }));
  };
  //console.log('values ', values);
  const handleSubmit = async () => {
    if (mode === 'add') {
      try {
        const isAddedSuccessful = await handleAddCodes();
        if (!isAddedSuccessful) return;
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      try {
        await handleUpdateCodes();
      } catch (error) {
        console.log('update error : ', error);
      }
    }
    closeDialog();
  };
  /* handler functions -> end */

  /* APi Calls -> start */
  const checkDuplication = async query => {
    const service = getService(currentService);
    const isAlreadyExist = await service.find({ query });
    if (isAlreadyExist.data.length) {
      setOpenAlert(true);
      return true;
    }
    return false;
  };
  const handleAddCodes = async () => {
    let response;
    let isExists = false;
    const service = getService(currentService);
    switch (currentService) {
      case 'codepartyrelationship':
        isExists = await checkDuplication({ Code: values.Code, isDeleted: false });
        if (isExists) return false;
        response = await service.create(formatPartyRelationShip(values));
        break;
      case 'code-dispositions':
        isExists = await checkDuplication({
          AgencyID: values.AgencyID,
          Code: values.Code,
          IsDeleted: false,
        });
        if (isExists) return false;
        response = await service.create(formatCodeDispositions(values));
        break;
      case 'codecities':
        response = await service.create(formatCities(values));
        break;
      case 'codezonetypes':
        /* AgencyID */
        isExists = await checkDuplication({ AgencyID: values.AgencyID, IsDeleted: false });
        if (isExists) return false;
        response = await service.create(formatCodeZonesTypes(values));
        break;
      case 'codeunitactions':
        isExists = await checkDuplication({ Code: values.Code, IsDeleted: false });
        if (isExists) return false;
        response = await service.create(formatCodeUnitActions(values));
        break;
      case 'code-vehicle-make-models':
        isExists = await checkDuplication({
          MakeCode: values.MakeCode,
          ModelCode: values.ModelCode,
          IsDeleted: false,
        });
        if (isExists) return false;
        response = await service.create(formatCodeVehicleMakeModels(values));
        break;
      default:
        isExists = await checkDuplication({ Code: values.Code, IsDeleted: false });
        if (isExists) return false;
        response = await service.create(formatCommonCodes(values));
        break;
    }
    return true;
  };
  const handleUpdateCodes = async () => {
    const service = getService(currentService);
    switch (currentService) {
      case 'codepartyrelationship':
        await service.patch(values.Code, formatPartyRelationShip(values));
        break;
      case 'codecities':
        await service.patch(values.ptsCityID, formatCities(values));
        break;
      case 'code-dispositions':
        await service.update(
          { AgencyID: values.AgencyID, Code: values.Code },
          formatCodeDispositions(values)
        );
        break;
      case 'codezonetypes':
        await service.patch(values.AgencyID, formatCodeZonesTypes(values));
        break;
      case 'codeunitactions':
        await service.patch(values.Code, formatCodeUnitActions(values));
        break;
      case 'code-vehicle-make-models':
        await service.update(
          { MakeCode: values.MakeCode, ModelCode: values.ModelCode },
          formatCodeVehicleMakeModels(values)
        );
        break;
      case 'codezones':
        await service.patch(values.ZoneCode, formatCodeZones(values));
        break;
      case 'codetagtypes':
        await service.patch(values.Code, formatTagTypes(values));
        break;
      default:
        await service.patch(values.Code, formatCommonCodes(values));
        break;
    }
  };
  /* Api Calls -> end */

  /* useEffect -> start */
  useEffect(() => {
    if (mode === 'edit') {
      console.log('editable data ', data);
      setValues({ ...data });
    } else {
      setValues(obj);
    }
  }, [mode, data]);

  useEffect(() => {
    if (['code-dispositions', 'codezonetypes', 'codezones'].includes(currentService)) {
      const fetchAgencies = async () => {
        const service = getService('ptsagencies');
        const response = await service.find({ query: { IsDeleted: false } });
        const newArray = response?.data.map(el => el?.AgencyID);
        setAgencyOptions(newArray);
        //console.log('response agencies ', response);
      };
      fetchAgencies();
    } else if (currentService === 'code-vehicle-make-models') {
      const fetchAgencies = async () => {
        const service = getService('codevehiclemakes');
        const response = await service.find({ query: { IsDeleted: false } });
        const newArray = response?.data.map(el => el?.Code);
        setMakeOptions(newArray);
        //console.log('response agencies ', response);
      };
      fetchAgencies();
    }
  }, [currentService]);

  /* useEffect -> end */

  /* Render Functions -> start */
  const commonForm = () => (
    <>
      {currentService !== 'codezonetypes' && (
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          {currentService !== 'codezones' && (
            <Grid item xs={6} style={{ width: '100%' }}>
              <Box style={{ marginLeft: 10 }}>
                <TextField
                  //fullWidth
                  disabled={mode === 'edit' && currentService !== 'code-vehicle-make-models'}
                  style={{ width: '100%' }}
                  type={'text'}
                  value={values.Code}
                  label={'Code/Description'}
                  //error={error}
                  variant={'outlined'}
                  onChange={e => handleChange('Code', e.target.value)}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={6} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <TextField
                //fullWidth
                style={{ width: '100%' }}
                type={'text'}
                value={values.Description}
                //onChange={handleChange}
                onChange={e => handleChange('Description', e.target.value)}
                helperText={''}
                label={'Description'}
                //error={error}
                variant={'outlined'}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Code2}
              label={'Code 2/Description 2'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Code2', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description2}
              //onChange={handleChange}
              onChange={e => handleChange('Description2', e.target.value)}
              helperText={''}
              label={'Description 2'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Code3}
              label={'Code 3/ Description 3'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Code3', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description3}
              //onChange={handleChange}
              onChange={e => handleChange('Description3', e.target.value)}
              helperText={''}
              label={'Description 3'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Code4}
              label={'Code 4/ Description 4'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Code4', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description4}
              //onChange={handleChange}
              onChange={e => handleChange('Description4', e.target.value)}
              helperText={''}
              label={'Description 4'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Category}
              label={'Category'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Category', e.target.value)}
            />
          </Box>
        </Grid>
        {currentService === 'codealerttypes' && (
          <Grid item xs={2} style={{ width: '100%' }}>
            <Box style={{ marginLeft: 10 }}>
              <Box style={{ marginLeft: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.Flags?.trim() == 'enable'}
                      onChange={e => handleChange('Flags', e.target.checked ? 'enable' : 'disable')}
                    />
                  }
                  label="Enable Notification"
                />
              </Box>
            </Box>
          </Grid>
        )}

        <Grid item xs={2}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsDefault}
                  onChange={e => handleChange('IsDefault', e.target.checked)}
                />
              }
              label="Default"
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsActive}
                  onChange={e => handleChange('IsActive', e.target.checked)}
                />
              }
              label="Active"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
  const codePartyRelationShipForm = () => (
    <>
      {' '}
      {commonForm()}
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Entities}
              label={'Entities'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Entities', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={3} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'number'}
              value={values.Order}
              //onChange={handleChange}
              onChange={e => handleChange('Order', e.target.value)}
              helperText={''}
              label={'Order'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.EmployeeRelated}
                  onChange={e => handleChange('EmployeeRelated', e.target.checked)}
                />
              }
              label="EmployeeRelated"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
  const codeCitiesForm = () => (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              disabled={mode === 'edit'}
              style={{ width: '100%' }}
              type={'text'}
              value={values.CityDescription}
              label={'City Description'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('CityDescription', e.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description2}
              label={'Description 2'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Description2', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Description3}
              //onChange={handleChange}
              onChange={e => handleChange('Description3', e.target.value)}
              helperText={''}
              label={'Description 3'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Category}
              label={'Category'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('Category', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsDefault}
                  onChange={e => handleChange('IsDefault', e.target.checked)}
                />
              }
              label="Default"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsActive}
                  onChange={e => handleChange('IsActive', e.target.checked)}
                />
              }
              label="Active"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
  const codeDispositionForm = () => (
    <>
      {' '}
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Autocomplete
              options={agencyOptions}
              value={values.AgencyID}
              renderInput={params => <TextField {...params} label="Agency" variant="outlined" />}
              onChange={(event, selection) => handleChange('AgencyID', selection)}
              disabled={mode === 'edit'}
            />
          </Box>
        </Grid>
      </Grid>
      {commonForm()}
    </>
  );

  const codeVehicleMakeModelsForm = () => (
    <>
      {' '}
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Autocomplete
              options={makeOptions}
              value={values.MakeCode}
              renderInput={params => <TextField {...params} label="Make" variant="outlined" />}
              onChange={(event, selection) => handleChange('MakeCode', selection)}
              disabled={mode === 'edit'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              disabled={mode === 'edit'}
              style={{ width: '100%' }}
              type={'text'}
              value={values.ModelCode}
              label={'Model Code'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('ModelCode', e.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth

              style={{ width: '100%' }}
              type={'text'}
              value={values.MakeDescription}
              label={'Make Description'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('MakeDescription', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth

              style={{ width: '100%' }}
              type={'text'}
              value={values.ModelDescription}
              label={'Model Description'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('ModelDescription', e.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
      {commonForm()}
    </>
  );

  const codeTagTypesForm = () => (
    <>
      {commonForm()}
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={3}>
          <Box style={{ marginLeft: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.Priority}
                  onChange={e => handleChange('Priority', e.target.checked)}
                />
              }
              label="Priority"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
  const codeZonesForm = () => (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.ZoneCode}
              //onChange={handleChange}
              onChange={e => handleChange('ZoneCode', e.target.value)}
              helperText={''}
              label={'Zone Code'}
              disabled={mode === 'edit'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Autocomplete
              options={agencyOptions}
              value={values.AgencyID}
              renderInput={params => <TextField {...params} label="Agency" variant="outlined" />}
              onChange={(event, selection) => handleChange('AgencyID', selection)}
              disabled={mode === 'edit'}
            />
          </Box>
        </Grid>
      </Grid>
      {commonForm()}
    </>
  );

  const codeZoneTypesForm = () => (
    <>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <Autocomplete
              options={agencyOptions}
              value={values.AgencyID}
              renderInput={params => <TextField {...params} label="Agency" variant="outlined" />}
              onChange={(event, selection) => handleChange('AgencyID', selection)}
              disabled={mode === 'edit'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone1Type}
              //onChange={handleChange}
              onChange={e => handleChange('Zone1Type', e.target.value)}
              helperText={''}
              label={'Zone1 Type'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone2Type}
              //onChange={handleChange}
              onChange={e => handleChange('Zone2Type', e.target.value)}
              helperText={''}
              label={'Zone2 Type'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone3Type}
              //onChange={handleChange}
              onChange={e => handleChange('Zone3Type', e.target.value)}
              helperText={''}
              label={'Zone3 Type'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone4Type}
              //onChange={handleChange}
              onChange={e => handleChange('Zone4Type', e.target.value)}
              helperText={''}
              label={'Zone4 Type'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth
              style={{ width: '100%' }}
              type={'text'}
              value={values.Zone5Type}
              //onChange={handleChange}
              onChange={e => handleChange('Zone5Type', e.target.value)}
              helperText={''}
              label={'Zone5 Type'}
              //error={error}
              variant={'outlined'}
            />
          </Box>
        </Grid>
      </Grid>
      {commonForm()}
    </>
  );

  const codeUnitActionForm = () => (
    <>
      {' '}
      {commonForm()}
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Grid item xs={6} style={{ width: '100%' }}>
          <Box style={{ marginLeft: 10 }}>
            <TextField
              //fullWidth

              style={{ width: '100%' }}
              type={'number'}
              value={values.WaitTime}
              label={'Wait Time'}
              //error={error}
              variant={'outlined'}
              onChange={e => handleChange('WaitTime', e.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
  const renderFunc = () => {
    switch (currentService) {
      case 'codecities':
        return codeCitiesForm();
      case 'codepartyrelationship':
        return codePartyRelationShipForm();
      case 'code-dispositions':
        return codeDispositionForm();
      case 'codezonetypes':
        return codeZoneTypesForm();
      case 'codeunitactions':
        return codeUnitActionForm();
      case 'code-vehicle-make-models':
        return codeVehicleMakeModelsForm();
      case 'codezones':
        return codeZonesForm();
      case 'codetagtypes':
        return codeTagTypesForm();
      default:
        return commonForm();
    }
  };
  const displayAlert = () => (
    <Snackbar
      open={openAlert}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={6000}
      onClose={handleCloseAlert}>
      <Alert onClose={handleCloseAlert} severity="warning" sx={{ width: '100%' }}>
        Item Already Exists!!
      </Alert>
    </Snackbar>
  );
  /* Render Functions -> end */

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth={'lg'} fullWidth={true}>
      <DialogTitle style={{ background: '#1976d2', color: 'white' }}>
        {<Typography variant="h5">{title}</Typography>}
      </DialogTitle>
      <DialogContent>
        {displayAlert()}
        <div style={{ padding: 10 }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>{renderFunc()}</Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <div className=" mb-1">
          <Button style={{ background: '#f1f1f1', color: 'black' }} onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            className="ml-2"
            variant="contained"
            style={{ background: '#1976D2', color: 'white' }}
            onClick={handleSubmit}>
            {mode === 'edit' ? 'Update' : 'Save'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
