import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';
import ResetPassword from 'pages/ResetPassword';

import { setWebsocketConnection } from './reducers/WebsocketReducer';
import { setUserAuthenticated, setUserData } from './reducers/UserReducer';
import { ClimbingBoxLoader } from 'react-spinners';
import * as serviceWorker from './serviceWorker';
import { notifyPanel } from './reducers/NotifierReducer';
import Cookies from 'js-cookie';

// Layout Blueprints
import { MinimalLayout, CadLayout } from './layout-blueprints';

// Pages
import PageLogin from './pages/PageLogin';
import Page404 from './pages/Page404';
import LogOut from 'pages/LogOut';

import routeDefinitions, { defaultUrl } from './routeDefinitions';

let redirectTo = defaultUrl;

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const { path } = rest;
  if (!isAuthenticated && path) redirectTo = path;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/Login', state: { from: props.location } }} />
        )
      }
    />
  );
}

const LoginRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated === true ? (
        <Redirect to={{ pathname: redirectTo, state: { from: props.location } }} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const Routes = props => {
  const {
    isAuthenticated,
    setWebsocketConnection,
    setUserAuthenticated,
    setUserData,
    setLoggedInUserData,
    configLoaded,
  } = props;

  React.useEffect(() => {
    // handle service worker updates
    const updateApp = registration => {
      window.localStorage.clear();
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', event => {
          if (event.target.state === 'activated') {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    };
    const openNotification = registration => {
      const notification = {
        title: `Application Update Available!`,
        message: `New release of CAD Application has been released.It is highly recomended to launch the new version!!`,
        position: 'tc',
        level: 'warning',
        autoDismiss: 0,
        action: {
          label: 'LAUNCH!',
          callback: () => updateApp(registration),
        },
      };
      props.notifyPanel(notification, 'success');
    };
    serviceWorker.register({
      onSuccess: () => console.log('service worker registered!'),
      onUpdate: reg => openNotification(reg),
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    async function establishConnection() {
      const client = feathers();
      client.configure(
        socketio(
          io(process.env.REACT_APP_API_URL, {
            transports: ['websocket', 'polling'],
            perMessageDeflate: false,
            timeout: 20000,
            requestTimeout: 20000,
          })
        )
      );
      client.configure(auth({ storageKey: 'auth' }));
      try {
        const auth = Cookies.get('auth');
        if (auth) {
          window.localStorage.setItem('auth', auth);
          Cookies.remove('auth');
        }
        const user = await client.reAuthenticate();
        setWebsocketConnection(client);
        setUserData(user);
        setUserAuthenticated(true);
      } catch (error) {
        if (error.code === 401) {
          setUserAuthenticated(false);
          setUserData(null);
          setWebsocketConnection(client);
        }
        setWebsocketConnection(client);
      }
    }
    establishConnection();
    // eslint-disable-next-line
  }, [setWebsocketConnection, setUserAuthenticated, setLoggedInUserData]);

  const SuspenseLoading = () => {
    return (
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={'#0153a3'} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Please wait while we load the view...
        </div>
      </div>
    );
  };

  const loadingPage = props => <h3>Loading...</h3>;

  return (
    <Suspense fallback={<SuspenseLoading />}>
      <Switch>
        <Redirect exact from="/" to="/Login" />
        <Route path={['/Login']}>
          <MinimalLayout>
            <LoginRoute path="/Login" component={PageLogin} isAuthenticated={isAuthenticated} />
          </MinimalLayout>
        </Route>
        <Route path={['/logout']}> <LogOut /></Route>
        <Route path={['/Reset-password/:hash']}>
          <MinimalLayout>
            <ResetPassword isAuthenticated={isAuthenticated} />
          </MinimalLayout>
        </Route>
        {routeDefinitions.map((route, key) => {
          const Layout = route.layout;
          return (
            <Route path={[route.url]} key={key}>
              <Layout>
                <ProtectedRoute
                  path={route.url}
                  component={configLoaded ? route.component : loadingPage}
                  isAuthenticated={isAuthenticated}
                />
              </Layout>
            </Route>
          );
        })}

        <Route>
          <CadLayout>
            <ProtectedRoute component={Page404} isAuthenticated={isAuthenticated} />
          </CadLayout>
        </Route>
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = state => ({
  network: state.offline,
  isAuthenticated: state.user.isAuthenticated,
  configLoaded: state.config.loaded,
});

export default connect(mapStateToProps, {
  setWebsocketConnection,
  setUserAuthenticated,
  setUserData,
  notifyPanel,
})(Routes);
