/**
 * Component will contain all dialogs that could be displayed. Currently it is dialog for Adding Page only.
 */
import React from 'react';
import { connect } from 'react-redux';
import GroupEdit from '../Settings/PagingSetup/GroupEdit';
import RecipientEdit from '../Settings/PagingSetup/RecipientEdit';
import TemplateEdit from '../Settings/PagingSetup/TemplateEdit';
import AddSop from './AddSOP';
import AddSecUserDialog from './AddSecUserDialog';
import RemoveSecUserDialog from './RemoveSecUserDialog';
import OptionsDialog from './OptionsDialog';
import AddSecRoleDialog from './AddSecRoleDialog';
import AddSecProfileDialog from './AddSecProfileDialog';
import EditSecRoleDialog from './EditSecRoleDialog';
import EditSecProfileDialog from './EditSecProfileDialog';
import ImportEsns from 'Settings/GeofenceDesigner/ImportEsns';
import ImportZones from 'Settings/GeofenceDesigner/ImportZones';
import AddCitationFile from './AddCitationFile';
import AddAgencyUnitSelection from './AddAgencyUnitSelection';
import EditAgency from './Agencies/EditAgency';
import ContactMethodDialog from './ContactMethodDialog';
import PlaceEmployeeDialog from './PlaceEmployeeDialog';
import VehicleRegDialog from './VehicleRegDialog';
import EditPlaceAddressDialog from './EditPlaceAddressDialog';
import AddCitationCourt from './AddCitationCourt';

function Dialogs(props) {
  const { isAuthenticated, dialogs } = props;
  const {
    editPageGroup,
    editPageRecipient,
    editPageTemplate,
    editSop,
    addSecUser,
    removeSecUser,
    optionsDialog,
    addSecRole,
    addSecProfile,
    editSecRole,
    editSecProfile,
    importZones,
    importEsns,
    addCitationFile,
    addAgencyUnitSelection,
    editAgency,
    editContactMethod,
    placeEmployment,
    editVehicleReg,
    editPlaceAddress,
    addCitationCourt,
  } = dialogs;

  return (
    <>
      {isAuthenticated && (
        <>
          {editPageGroup !== null && <GroupEdit ptsGroupID={editPageGroup} />}
          {editPageRecipient !== null && <RecipientEdit ptsRecipientID={editPageRecipient} />}
          {editPageTemplate !== null && <TemplateEdit ROWGUID={editPageTemplate} />}
          {Boolean(editSop) && <AddSop ptsSOPID={editSop} />}
          {addSecUser !== null && <AddSecUserDialog data={addSecUser} />}
          {removeSecUser !== null && <RemoveSecUserDialog data={removeSecUser} />}
          {optionsDialog !== null && <OptionsDialog data={optionsDialog} />}
          {addSecRole !== null && <AddSecRoleDialog data={addSecRole} />}
          {editSecRole !== null && <EditSecRoleDialog groupId={editSecRole} />}
          {addSecProfile !== null && <AddSecProfileDialog data={addSecProfile} />}
          {editSecProfile !== null && <EditSecProfileDialog profileId={editSecProfile} />}
          {editAgency !== null && <EditAgency AgencyID={editAgency} />}
          {importEsns !== null && <ImportEsns options={importEsns} />}
          {importZones !== null && <ImportZones options={importZones} />}
          {addCitationFile !== null && <AddCitationFile data={addCitationFile} />}
          {addCitationCourt !== null && <AddCitationCourt data={addCitationCourt} />}
          {addAgencyUnitSelection !== null && (
            <AddAgencyUnitSelection data={addAgencyUnitSelection} />
          )}
          {editContactMethod !== null && <ContactMethodDialog data={editContactMethod} />}
          {placeEmployment !== null && <PlaceEmployeeDialog data={placeEmployment} />}
          {editVehicleReg !== null && <VehicleRegDialog vehicleData={editVehicleReg} />}
          {editPlaceAddress !== null && <EditPlaceAddressDialog ptsAddressID={editPlaceAddress} />}
        </>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    dialogs: state.dialogs,
  };
};

export default connect(mapStateToProps)(Dialogs);
