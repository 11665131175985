import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './Routes';
import store from './config/configureStore';
import { history } from './config/configureStore';
import ScrollToTop from './utils/ScrollToTop';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DayTheme, NightTheme } from './theme';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Notifier from 'global-components/Notifier';
import { AnimatePresence } from 'framer-motion';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Spinner from 'components/Spinner';
import PerfectScrollbar from 'react-perfect-scrollbar';
import NotificationPanel from './components/NotificationPanel';
import Dialogs from './Dialogs';
import { useLoadScript } from '@react-google-maps/api';
import { notify } from './reducers/NotifierReducer';
import './assets/base.scss';
import './icons';
import { getDictionary } from 'reducers/DictionaryReducer';

import { subscribeEvents, unsubscribeEvents } from './reducers/EventsReducer';
import { subscribeUnits, unsubscribeUnits } from './reducers/UnitsReducer';
import { getSettings } from './reducers/ConfigReducer';
import { updateZones } from './reducers/ZonesReducer';

import { LicenseInfo } from '@material-ui/x-grid';
import IdleTimerContainer from 'components/IdleTimerContainer';
LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_KEY);

const mapLibraries = (process.env.REACT_APP_GOOGLE_LIBRARIES || '').split(',');

const App = props => {
  const snackbarRef = useRef();
  const [mapError, setMapError] = useState(false);
  const { 
    authenticated, websocket, ui, getSettings, getDictionary, 
  } = props;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries: mapLibraries
  });
  if (loadError && !mapError) setMapError(true);

  const { showSpinner } = ui;

  const themeMode = () => {
    let theme = DayTheme;
    if (props.mode === 'night') {
      theme = NightTheme;
    }
    return theme;
  };

  useEffect(() => {
    if (!mapError) return;
    props.notify('Map loading error', 'error');
    // eslint-disable-next-line
  }, [mapError]);

  useEffect(() => {
    if (websocket.websocket && authenticated) {
      subscribeEvents();
      subscribeUnits();
      getSettings();
      updateZones();
      getDictionary();
    }
    return () => {
      unsubscribeEvents();
      unsubscribeUnits();
    }
    // eslint-disable-next-line
  }, [websocket, authenticated]);

  const closeSnackbar = key => () => {
    snackbarRef.current.closeSnackbar(key);
  };

  if (!isLoaded) return 'Loading Maps...';
  return (
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={store.persistor}>
        <CssBaseline />
        <ScrollToTop>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={themeMode()}>
              <SnackbarProvider
                ref={snackbarRef}
                action={key => (
                  <IconButton onClick={closeSnackbar(key)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}>
                <Notifier />
                <NotificationPanel />
                <IdleTimerContainer />
                <AnimatePresence>
                  <PerfectScrollbar>
                    <div style={{height: '100vh'}}>
                      <Routes />
                    </div>
                  </PerfectScrollbar>
                </AnimatePresence>
              </SnackbarProvider>
              <Dialogs />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </ScrollToTop>
      </PersistGate>
      {showSpinner && <Spinner />}
    </ConnectedRouter>
  );
};

const mapStateToProps = state => ({
  mode: state.theme.mode,
  ui: state.ui,
  websocket: state.websocket,
  authenticated: state.user.isAuthenticated,
  // addPage: state.paging.addPage,
});

export default connect(mapStateToProps, { 
  getSettings, notify, getDictionary, 
})(App);
