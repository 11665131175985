import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { findSecComponents, findSecGroupComponents, saveGroupBooleans } from '../SecFunctions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { Fills } from 'utils/formStyles';
import { isDevelopment } from 'utils/functions';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';

const useStyles = makeStyles((theme) => ({
  checkStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '5px'
  },
  item: {
    flex: '1 1 250px',
    margin: '5px'
  }
}));

function PermissionsApps(props) {
  const classes = useStyles();
  const { role } = props;
  const [allModules, setAllModules] = useState([]);
  const [currentRoleWithModules, setCurrentRoleWithModules] = useState(null);
  const [existingModuleIds, setExistingModuleIds] = useState([]);


  useEffect(() => {
    fetchAndProcessData();
  }, [role])


  const fetchAndProcessData = async () => {
    props.showSpinner();
    const allComponents = await findSecComponents();
    const filteredComponets = allComponents.filter(c => c.Type === 'Modules');
    setAllModules(filteredComponets);

    const allGroupComponents = await findSecGroupComponents();
    const filtered = allGroupComponents.filter(el => el.ptsGroupID === role.ptsGroupID && el.Permissions === 1);

    const modules = [];
    for (const module of filteredComponets) {
      const found = filtered.find(el => module.ptsComponentID === el.ptsComponentID);
      if (found) modules.push(module.ptsComponentID);
    }

    const newRole = { ...role, Applications: modules };
    setCurrentRoleWithModules(newRole);

    const existingComponents = allGroupComponents.filter(el => el.ptsGroupID === role.ptsGroupID);
    const existingIds = [];
    for (const module of filteredComponets) {
      const found = existingComponents.find(el => module.ptsComponentID === el.ptsComponentID);
      if (found) existingIds.push(module.ptsComponentID);
    }
    setExistingModuleIds(existingIds);
    props.hideSpinner();
  }


  const handleSaveClick = async () => {
    const data = {
      GroupId: role.ptsGroupID,
      ExistingComponents: existingModuleIds,
      CurrentComponents: currentRoleWithModules.Applications
    }
    try {
      await saveGroupBooleans(data);
      fetchAndProcessData();
      props.notify("Application Permissions Updated", "success")
    } catch (err) {
      props.handleError(err, 'Error Updating Application Permissions');
    }
  }


  const renderModule = (module) => {
    const handleChange = () => {
      const newModulesIds = [...currentRoleWithModules.Applications];
      const foundIdx = newModulesIds.indexOf(module.ptsComponentID);
      if (foundIdx === -1) {
        newModulesIds.push(module.ptsComponentID);
      }
      else newModulesIds.splice(foundIdx, 1);
      const newRoleWithModules = { ...currentRoleWithModules, Applications: newModulesIds };
      setCurrentRoleWithModules(newRoleWithModules);
    }

    const handleChecked = () => {
      if (!currentRoleWithModules) return false;
      const currentModulesIds = [...currentRoleWithModules.Applications];
      const found = currentModulesIds.find(modId => modId === module.ptsComponentID);
      return Boolean(found);
    }

    const label = isDevelopment ? `${module.Label} (${module.ptsComponentID})` : module.Label;
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={handleChecked()}
            onChange={handleChange}
            color="primary"
          />
        }
        label={label}
      />
    )
  }


  const renderSaveButton = () => {
    return (
      <div>
        <Button
          onClick={handleSaveClick}
          variant="contained"
          className="mt-4"
          color="primary">
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    )
  }


  return (
    <>
      <div className={classes.checkStyle}>
        {allModules.map(m => (
          <span key={m.ptsComponentID} className={classes.item}>
            {renderModule(m)}
          </span>
        ))}
        <Fills className={classes.item} />
      </div>
      {renderSaveButton()}
    </>
  );
}


export default connect(null, { handleError, notify, showSpinner, hideSpinner, })(PermissionsApps);

